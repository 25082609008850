@import (reference) "../../AvalonComponents/Styles/Variables/breakpoints";
@import (reference) "../../AvalonComponents/Styles/Colors/_colors";

.ds-regionspots {
  display: grid;
  gap: 1.6rem;
  grid-template-columns: repeat(2, 1fr);
  margin: 0 auto;

  @media @lg {
    gap: 4rem;
    grid-template-columns: repeat(4, 1fr);
  }
}

.ds-regionspot {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 0.8rem 0.8rem 0.6rem 0.6rem;
  overflow: hidden;
  position: relative;
  width: 100%;
  box-shadow: 0 0.1rem 0.4rem 0 #0000001A;
  aspect-ratio: 3 / 4;

  &--mega {
    @media @sm {
      aspect-ratio: 2 / 1;
    }

    @media @lg {
      grid-column: 1 / span 4;
      aspect-ratio: 2 / 1;
    }
  }

  &--medium {
    aspect-ratio: 23 / 14;
  }
}

.ds-regionspot__pool {
  position: absolute;
  inset: 0 0 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.02em;
  color: white;
  white-space: nowrap;
  text-align: center;
  font-size: 1rem; // base font size for all spots, modified with em's below
  line-height: 1;

  // Mega
  .ds-regionspot--mega & {
    inset: 0 0 3rem;
    font-size: 1.6rem;
  }

  // Medium
  .ds-regionspot--medium & {
    font-size: 1.3rem;
  }


  @media @sm {
    font-size: 1.8rem;

    // Mega
    .ds-regionspot--mega & {
      font-size: 1.8rem;
    }

    // Medium
    .ds-regionspot--medium {
      font-size: 1.8rem;
    }
  }

  @media @lg {
    font-size: 1.3rem;

    // Mega
    .ds-regionspot--mega & {
      font-size: 2.5rem;
    }

    // Medium
    .ds-regionspot--medium & {
      font-size: 1.6rem;
    }
  }
}

.ds-regionspot__pool-title {
  opacity: 0.8;
  text-transform: uppercase;
  font-size: 1em;
  margin-bottom: 0.2em;
  font-weight: 700;
  letter-spacing: 0.02em;
}

.ds-regionspot__pool-fallback-postfix {
  font-size: 1.8em;
  font-weight: 800;
  opacity: 0.8;
  letter-spacing: -0.02em;
  margin-bottom: 0.2em;
}

.ds-regionspot__pool-size {
  text-wrap: pretty;
  white-space: nowrap;
  font-size: 3.2em;
  line-height: 1.1;
  font-weight: 800;
  text-transform: uppercase;

  // Mega
  .ds-regionspot--mega & {
    font-size: 4.3em;
  }

  // Medium
  .ds-regionspot--medium & {
    font-size: 4em;
  }

  @media @sm {
    // Medium
    .ds-regionspot--medium & {
      font-size: 6em;
    }
  }

  @media @lg {
    // Mega
    .ds-regionspot--mega & {
      font-size: 4.3em;
    }

    // Medium
    .ds-regionspot--medium & {
      font-size: 4.3em;
    }
  }
}

.ds-regionspot__flip-clock {
  margin: 0.8em 0 0.5em;
  font-size: 0.75em;
}

.ds-regionspot__oddset {
  position: absolute;
  inset: 1rem 0 5rem;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: @ds-white;
  font-style: italic;
  text-transform: uppercase;
  font-family: AvenirNext, Impact, 'Anton Regular', Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  line-height: 1;
  text-align: center;

  .ds-regionspot--feed-oddset &[data-fetch="success"] {
    display: flex;
  }

  // Mega
  .ds-regionspot--mega & {
    font-size: 3rem;
  }

  // Medium
  .ds-regionspot--medium & {
    font-size: 2.4rem;
  }

  @media @sm {
    font-size: 3rem;

    // Mega
    .ds-regionspot--mega & {
      font-size: 4rem;
    }

    // Medium
    .ds-regionspot--medium & {
      font-size: 4rem;
    }
  }

  @media @lg {
    font-size: 2rem;

    // Mega
    .ds-regionspot--mega & {
      font-size: 5rem;
    }

    // Medium
    .ds-regionspot--medium & {
      font-size: 3rem;
    }
  }
}

.ds-regionspot__oddset-team-name {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 1rem;
}

.ds-regionspot__oddset-team-separator {
  line-height: 0.4em;
  font-size: 1.4em;
  margin-bottom: 0.2em;
}

.ds-regionspot__oddset-odds {
  margin-top: 1.6em;
  display: flex;
  gap: 1em;
  font-size: 0.55em;
  line-height: 1;

  // Mega
  .ds-regionspot--mega & {
    font-size: 0.55em;
  }

  // Medium
  .ds-regionspot--medium & {
    font-size: 0.55em;
  }

  @media @sm {
    font-size: 0.6em;
  }

  @media @lg {
    font-size: 0.65em;

    // Mega
    .ds-regionspot--mega & {
      font-size: 0.52em;
    }

    // Medium
    .ds-regionspot--medium & {
      font-size: 0.52em;
    }
  }
}

.ds-regionspot__oddset-odds-1,
.ds-regionspot__oddset-odds-x,
.ds-regionspot__oddset-odds-2 {
  position: relative;
  padding-left: 1.8em;

  &::before {
    position: absolute;
    left: 0;
    top: -0.3em;
    width: 1.4em;
    height: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 0.1em;
    background: #FF7411;
    content: "1";
  }
}

.ds-regionspot__oddset-odds-x::before {
  padding-right: 0.2em;
  content: "X";
}


.ds-regionspot__oddset-odds-2::before {
  content: "2";
}


.ds-regionspot__fg {
  position: absolute;
  inset: 2.5rem 1rem 6.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .ds-regionspot:has([data-fetch="loading"]) &,
  .ds-regionspot:has([data-fetch="success"]) & {
    display: none;
  }

  // Mega
  .ds-regionspot--mega & {
    inset: 10.2rem 1.5rem;
  }

  // Medium
  .ds-regionspot--medium & {
    inset: 2.4rem 2rem 5.6rem;
  }

  @media @sm {
    inset: 2.5rem 2rem 6.5rem;

    // Mega
    .ds-regionspot--mega & {
      inset: 9rem 4rem 10.2rem;
    }

    // Medium
    .ds-regionspot--medium & {
      inset: 4rem 4rem 8rem;
    }
  }

  @media @lg {
    inset: 2.5rem 1rem 6.5rem;

    // Mega
    .ds-regionspot--mega & {
      inset: 11.8rem 8rem 12.2rem;
    }

    // Medium
    .ds-regionspot--medium & {
      inset: 3.5rem 3rem 7.5rem;
    }
  }

  .ds-regionspot:has(.ds-regionspot__pool) & {
    display: none;
  }

  picture,
  img {
    width: 100%;
    height: 100%;
    line-height: 0;
  }

  img {
    object-fit: contain;
  }
}

.ds-regionspot__bg {
  background-color: var(--background-color, transparent);
  width: 100%;
  height: 100%;
  border-radius: 0.8rem;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.ds-regionspot__duty-text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 4rem;
  background: rgba(255, 255, 255, 0.9);
  padding: 0.9rem 1rem 0.5rem;
  font-size: 0.8rem;
  line-height: 1.2;
  font-weight: 600;
  color: @ds-gray-900;

  // Mega
  .ds-regionspot--mega & {
    background: none;
    bottom: auto;
    top: 3rem;
    color: @ds-white;
    text-align: center;
    font-size: 0.8rem;
    height: auto;
    padding: 0;

    .ds-regionspot__brand-logo {
      margin: 0 auto 0.5rem;
      height: 2.4rem;
      background-position: center;
      transform-origin: center;
    }
  }
}

.ds-regionspot__brand-logo {
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center left;
  width: 100%;
  height: 1.15rem;
  transform-origin: left;
  margin-bottom: 0.3rem;

  .ds-regionspot--allornothing & {
    transform: scale(1.15) translateY(-20%);
  }

  .ds-regionspot--bingo & {
    transform: scale(1.15);
  }

  .ds-regionspot--casino & {
    transform: scale(0.75) translateY(-20%);
  }

  .ds-regionspot--dantoto & {
    transform: scale(0.9) translateY(-10%);
  }

  .ds-regionspot--eurojackpot & {
    transform: scale(1.01) translateY(-5%);
  }

  .ds-regionspot--keno & {
    transform: scale(0.9) translateY(-8%);
  }

  .ds-regionspot--klasselotteriet & {
    transform: scale(1.3);
  }

  .ds-regionspot--livecasino &,
  .ds-regionspot--live-casino & {
    transform: scale(0.8);
  }

  .ds-regionspot--lotto & {
    transform: scale(0.92) translateY(-10%);
  }

  .ds-regionspot--oddset & {
    transform: scale(1.2) translateY(-10%);
  }

  .ds-regionspot--plus & {
    transform: scale(1.5) translateY(-10%);
  }

  .ds-regionspot--danske-spil & {
    transform: scale(1.5) translateY(-10%);
  }

  .ds-regionspot--poker & {
    transform: scale(0.75);
  }

  .ds-regionspot--quick & {
    transform: scale(1.2) translateY(0%);
  }

  .ds-regionspot--spillehjoernet & {
    transform: scale(3) translateY(-2%);
  }

  .ds-regionspot--spilsammen & {
    transform: scale(1.4) translateY(-5%);
  }

  .ds-regionspot--tips & {
    transform: scale(0.8) translateY(-10%);
  }

  .ds-regionspot--tivolicasino &,
  .ds-regionspot--tivoli-casino & {
    transform: scale(1.2) translateY(-14%);
  }

  .ds-regionspot--vikinglotto & {
    transform: scale(1.2);
  }
}

.makeBrandLogos(@brand) {
  @brandRaw: ~"@{brand}";
  .ds-regionspot--@{brandRaw} .ds-regionspot__brand-logo {
    background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/@{brand}.svg");
  }

  .ds-regionspot--mega.ds-regionspot--@{brandRaw} .ds-regionspot__brand-logo {
    background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/@{brand}-white.svg");
  }
}

.makeBrandLogos("allornothing");
.makeBrandLogos("bingo");
.makeBrandLogos("casino");
.makeBrandLogos("dantoto");
.makeBrandLogos("eurojackpot");
.makeBrandLogos("keno");
.makeBrandLogos("klasselotteriet");
.makeBrandLogos("livecasino");
.makeBrandLogos("live-casino");
.makeBrandLogos("lotto");
.makeBrandLogos("oddset");
.makeBrandLogos("plus");
.makeBrandLogos("poker");
.makeBrandLogos("quick");
.makeBrandLogos("spillehjoernet");
.makeBrandLogos("spilsammen");
.makeBrandLogos("tips");
.makeBrandLogos("tivolicasino");
.makeBrandLogos("tivoli-casino");
.makeBrandLogos("vikinglotto");
.makeBrandLogos("danske-spil");

.ds-regionspot__cta {
  display: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 3rem;
  background: @ds-yellow;
  height: 4rem;
  border-radius: 100rem;
  justify-content: center;
  align-items: center;
  width: calc(~"100% - 5.2rem");
  max-width: 27rem;
  margin: 0 auto;
  font-size: 1.2rem;
  line-height: 1;
  font-weight: 800;
  text-transform: uppercase;
  color: black;
  letter-spacing: 0.1em;
  padding: 0 4rem;
  white-space: nowrap;
  transition: background 300ms;

  &:hover {
    background: @ds-yellow-600;
  }

  @media @lg {
    bottom: 4rem;
    height: 5.6rem;
    width: unset;
  }

  // Mega
  .ds-regionspot--mega & {
    display: flex;
  }
}