// Actual color definitions from DS style guide page 22-24
@ds-neongreen: #56F4AD;
@ds-neongreen-divider: 11.5;

@ds-neongreen-100: lighten(@ds-neongreen, @ds-neongreen-divider * 4);
@ds-neongreen-200: lighten(@ds-neongreen, @ds-neongreen-divider * 3);
@ds-neongreen-300: lighten(@ds-neongreen, @ds-neongreen-divider * 2);
@ds-neongreen-400: lighten(@ds-neongreen, @ds-neongreen-divider * 1);
@ds-neongreen-500: @ds-neongreen;
@ds-neongreen-550: lighten(@ds-neongreen, @ds-neongreen-divider * -0.5);
@ds-neongreen-600: lighten(@ds-neongreen, @ds-neongreen-divider * -1);
@ds-neongreen-700: #44C289;
@ds-neongreen-800: lighten(@ds-neongreen, @ds-neongreen-divider * -3);
@ds-neongreen-900: lighten(@ds-neongreen, @ds-neongreen-divider * -4);
